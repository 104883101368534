import {
  addDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  collection,
  where,
  query,
  doc
} from "firebase/firestore";
import { fdb } from "../../fb";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import Loading from "../../components/Loading";

export default function Projects() {
  let { user } = useParams();
  let [projects, setProjects] = useState([]);
  let [loading, setLoading] = useState(true);

  const loadProjects = useCallback(async () => {
    if (user === "") return;

    let q = await query(collection(fdb, "projects"), where("user", "==", user));
    let ss = await getDocs(q);
    setProjects(ss.docs);
    setLoading(false);
  }, [user]);

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  async function addProject(obj) {
    if (
      obj.name === "" ||
      obj.preview_url === "" ||
      obj.img === "" ||
      obj.screenshot === "" ||
      obj.desc === ""
    )
      return;
    if (obj.code_url === "") delete obj.code_url;
    obj.user = user;
    obj.featured = false;
    obj.date = new Date().toISOString().split("T")[0];

    let c = collection(fdb, "projects");
    let docRef = await addDoc(c, obj);
    let t = { id: docRef.id, data: () => obj };
    setProjects([...projects, t]);
  }

  async function deleteProject(id) {
    await deleteDoc(doc(fdb, "projects", id));
    setProjects(projects.filter((p, i) => p.id !== id));
  }

  if (loading) return <Loading />;

  return (
    <div className="flex flex-col items-center">
      <div className="w-full mt-5 flex flex-col items-center">
        <div className="text-xl font-bold">Add New Project</div>
        <AddProject addProject={addProject} />
      </div>
      {projects
        .sort((a, b) => new Date(b.data().date) - new Date(a.data().date))
        .map((p, i) => (
          <Project key={p.id} project={p} deleteProject={deleteProject} />
        ))}
    </div>
  );
}

function AddProject({ addProject }) {
  let [name, setName] = useState("");
  let [desc, setDesc] = useState("");
  let [img, setImg] = useState("");
  let [screenshot, setScreenshot] = useState("");
  let [preview_url, setPreview_url] = useState("");
  let [code_url, setCode_url] = useState("");

  return (
    <div className="w-full">
      <div className="flex w-4/5 items-center mt-5">
        <div className="w-1/3 text-right">Name:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={name}
          placeholder="project name"
          onChange={(ev) => setName(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">Code URL:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={code_url}
          placeholder="only for codesandbox"
          onChange={(ev) => setCode_url(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">App URL:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={preview_url}
          placeholder="url to the app"
          onChange={(ev) => setPreview_url(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">Picture:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={img}
          placeholder="title screen picture"
          onChange={(ev) => setImg(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">Screenshot:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={screenshot}
          placeholder="screenshot of app"
          onChange={(ev) => setScreenshot(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">Description:</div>
        <textarea
          className="w-2/3 p-2 outline-none border mx-5 text-sm"
          value={desc}
          placeholder="description"
          onChange={(ev) => setDesc(ev.target.value)}
        ></textarea>
      </div>
      <button
        onClick={() =>
          addProject({ name, code_url, preview_url, img, screenshot, desc })
        }
        className="cursor-pointer mt-5 border py-2 px-5 bg-green-200"
      >
        Add
      </button>
    </div>
  );
}

function EditProject({ project, setProject, setEdit }) {
  let [name, setName] = useState(project.data().name);
  let [desc, setDesc] = useState(project.data().desc);
  let [img, setImg] = useState(project.data().img);
  let [screenshot, setScreenshot] = useState(project.data().screenshot);
  let [preview_url, setPreview_url] = useState(project.data().preview_url);
  let [code_url, setCode_url] = useState(project.data().code_url);

  async function updateProject() {
    let obj = { name, desc, img, screenshot, preview_url, code_url };
    let docRef = await doc(fdb, "projects", project.id);
    await updateDoc(docRef, obj);
    setProject({
      ...project,
      data: () => {
        return { ...project.data(), ...obj };
      }
    });
    setEdit(false);
  }

  return (
    <div className="w-full">
      <div className="flex w-4/5 items-center mt-5">
        <div className="w-1/3 text-right">Name:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={name}
          placeholder="project name"
          onChange={(ev) => setName(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">Code URL:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={code_url}
          placeholder="only for codesandbox"
          onChange={(ev) => setCode_url(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">App URL:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={preview_url}
          placeholder="url to the app"
          onChange={(ev) => setPreview_url(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">Picture:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={img}
          placeholder="title screen picture"
          onChange={(ev) => setImg(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">Screenshot:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={screenshot}
          placeholder="screenshot of app"
          onChange={(ev) => setScreenshot(ev.target.value)}
        />
      </div>
      <div className="flex w-4/5 items-center mt-2">
        <div className="w-1/3 text-right">Description:</div>
        <textarea
          className="w-2/3 p-2 outline-none border mx-5 text-sm"
          value={desc}
          placeholder="description"
          onChange={(ev) => setDesc(ev.target.value)}
        ></textarea>
      </div>
      <button
        onClick={updateProject}
        className="cursor-pointer mt-5 border py-2 px-5 bg-green-200"
      >
        Save
      </button>
    </div>
  );
}

function Project({ project, updateProject, deleteProject }) {
  let [p, setP] = useState({ id: project.id, data: () => project.data() });
  let [edit, setEdit] = useState(false);

  return (
    <div key={p.id} className="w-full flex-col flex items-center mt-5">
      <hr className="w-full" />
      {edit && (
        <div className="w-full mt-5 flex flex-col items-center">
          <div className="text-xl font-bold">Edit Project</div>
          <EditProject
            project={project}
            updateProject={updateProject}
            setProject={setP}
            setEdit={setEdit}
          />
        </div>
      )}
      {!edit && (
        <div className="mt-5 w-4/5 flex flex-col items-center">
          <div className="w-full flex items-end text-center">
            <div className="w-1/3 mt-2 flex items-center space-x-5">
              <a
                className="border p-2 truncate"
                target="blank"
                href={p.data().preview_url}
              >
                View App
              </a>
              {p.data().code_url && (
                <a
                  className="border p-2 truncate"
                  target="blank"
                  href={p.data().code_url}
                >
                  View Code Here
                </a>
              )}
            </div>
            <div className="w-1/3 text-xl font-bold">{p.data().name}</div>
            <div className="w-1/3 flex space-x-2">
              <div>Created:</div>
              <div>{p.data().date}</div>
            </div>
          </div>
          <img className="mt-5" alt="pic" src={p.data().img} />
          <div className="mt-2 text-sm">{p.data().desc}</div>

          <img className="mt-2" alt="screenshot" src={p.data().screenshot} />
        </div>
      )}
      <hr className="w-full" />
      {!edit && (
        <div className="flex w-4/5 justify-start space-x-5 mt-5">
          <button onClick={() => setEdit(!edit)} className="border p-2">
            Edit
          </button>
          <button onClick={() => deleteProject(p.id)} className="border p-2">
            Delete
          </button>
        </div>
      )}
    </div>
  );
}
