import { fdb } from "../../../fb";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

export default function Colors({ profile, setProfile, user }) {
  let [colors, setColors] = useState({ ...profile.colors });

  async function updateColors() {
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { colors });
    setProfile({ ...profile, colors });
  }

  return (
    <div className="w-full flex flex-col items-center">
      {Object.keys(colors)
        .sort()
        .map((c, i) => (
          <div className="mt-5 flex w-4/5 items-center space-x-5" key={c}>
            <div className="w-1/3 text-right">
              {c[0].toUpperCase() + c.substring(1)}:{" "}
            </div>
            <input
              className="p-2 border outline-none w-1/3"
              value={colors[c]}
              onChange={(ev) => setColors({ ...colors, [c]: ev.target.value })}
            />
            <div className="w-1/3">
              <div
                className="w-full p-5 border"
                style={{ backgroundColor: colors[c] }}
              ></div>
            </div>
          </div>
        ))}
      <div
        onClick={updateColors}
        className="cursor-pointer mt-5 border py-2 px-5 bg-yellow-200"
      >
        Update
      </div>
    </div>
  );
}
