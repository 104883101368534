import { fdb } from "../../../fb";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

const V1 = `<div style="position: relative; padding-bottom: 53.75%; height: 0;"><iframe src="`;
const V2 = `" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`;

function getVideo(video) {
  return video.substring(
    video.indexOf("src=") + 5,
    video.indexOf("frameborder") - 2
  );
}

export default function BasicInfo({ profile, setProfile, user }) {
  let [name, setName] = useState(profile.name);
  let [email, setEmail] = useState(profile.email);
  let [aboutme, setAboutme] = useState(profile.aboutme);
  let [video, setVideo] = useState(getVideo(profile.video));

  async function updateInfo(ev) {
    ev.preventDefault();
    let obj = {};
    if (name !== "") obj.name = name;
    if (email !== "") obj.email = email;
    if (aboutme !== "") obj.aboutme = aboutme;
    if (video !== "") obj.video = V1 + video + V2;
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { ...obj });
    setProfile({ ...profile, ...obj });
  }

  return (
    <form onSubmit={updateInfo} className="w-full flex flex-col items-center">
      <div className="m-5">
        Name:
        <input
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          placeholder="name"
          className="border p-2 outline-none mx-5"
        />
        E-mail:
        <input
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          placeholder="email"
          className="border p-2 outline-none mx-5"
        />
      </div>
      <hr className="w-full" />
      <div className="w-full m-5 flex flex-col items-center">
        About Me
        <textarea
          value={aboutme}
          onChange={(ev) => setAboutme(ev.target.value)}
          placeholder="about me"
          className="w-3/5 m-5 border p-2 outline-none"
        ></textarea>
      </div>
      <hr className="w-full" />
      <div className="m-5 w-4/5 flex items-center">
        Video Embed URL:
        <input
          value={video}
          onChange={(ev) => setVideo(ev.target.value)}
          className="border p-2 outline-none mx-5 flex-grow"
          placeholder="video embed link"
        />
      </div>
      <hr className="w-full" />
      <button className="m-5 border py-2 px-5 bg-yellow-200">Update</button>
    </form>
  );
}
