import { fdb } from "../../../fb";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

export default function Activities({ profile, setProfile, user }) {
  let [activities, setActivities] = useState([...profile.activities]);
  let [desc, setDesc] = useState("");
  let [img, setImg] = useState("");
  let [name, setName] = useState("");
  let [edit, setEdit] = useState(-1);
  let [confirm, setConfirm] = useState(-1);

  async function addActivity(ev) {
    ev.preventDefault();
    let t = [...profile.activities, { name, desc, img }];
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { activities: t });
    setProfile({ ...profile, activities: t });
    setActivities(t);
    setName("");
    setImg("");
    setDesc("");
  }

  async function updateActivity(activity) {
    let t = activities.map((a, i) => (i === edit ? activity : a));
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { activities: t });
    setProfile({ ...profile, activities: t });
    setActivities([...t]);
    setEdit(-1);
  }

  async function deleteActivity(index) {
    let activities = profile.activities.filter((p, i) => i !== index);
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { activities });
    setProfile({ ...profile, activities });
    setActivities([...activities]);
  }

  return (
    <div className="w-full flex flex-col items-center">
      <form onSubmit={addActivity} className="w-4/5">
        <div className="mt-5 flex items-center w-full">
          <div className="w-1/3">Name:</div>
          <input
            className="w-2/3 p-2 outline-none border mx-5"
            value={name}
            onChange={(ev) => setName(ev.target.value)}
          />
        </div>
        <div className="mt-5 flex items-center w-full">
          <div className="w-1/3">Image:</div>
          <input
            className="w-2/3 p-2 outline-none border mx-5"
            value={img}
            onChange={(ev) => setImg(ev.target.value)}
          />
        </div>
        <div className="mt-5 flex items-center w-full">
          <div className="w-1/3">Description:</div>
          <textarea
            className="w-2/3 p-2 outline-none border mx-5"
            value={desc}
            onChange={(ev) => setDesc(ev.target.value)}
          ></textarea>
        </div>
        <button className="cursor-pointer my-5 border py-2 px-5 bg-green-200">
          Add
        </button>
      </form>
      <hr className="w-full" />
      <div className="">
        {activities.map((a, i) =>
          edit !== i ? (
            <div className="w-full flex border-b" key={a.name}>
              <div className="w-2/3 border-r p-5">
                <div className="font-bold">{a.name}</div>
                <img className="mt-5" alt="activity" src={a.img} />
              </div>
              <div className="w-1/3">
                <div className="text-sm p-5">{a.desc}</div>
                <div>
                  <button
                    onClick={() => setEdit(i)}
                    className="cursor-pointer my-5 border py-2 px-5 bg-yellow-200"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() =>
                      confirm === i ? deleteActivity(i) : setConfirm(i)
                    }
                    className={
                      "cursor-pointer border py-2 px-5 " +
                      (confirm === i ? "bg-green-200" : "bg-red-200")
                    }
                  >
                    {confirm === i ? "Confirm" : "Delete"}
                  </button>
                  {confirm === i && (
                    <button
                      onClick={() => setConfirm(i)}
                      className="cursor-pointer my-5 border py-2 px-5 bg-red-200"
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <EditActivity
              key={a.name}
              activity={a}
              updateActivity={updateActivity}
            />
          )
        )}
      </div>
    </div>
  );
}

function EditActivity({ activity, updateActivity }) {
  let [name, setName] = useState(activity.name);
  let [img, setImg] = useState(activity.img);
  let [desc, setDesc] = useState(activity.desc);

  return (
    <div>
      <div className="mt-5 flex items-center w-full">
        <div className="w-1/3">Name:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
      </div>
      <div className="mt-5 flex items-center w-full">
        <div className="w-1/3">Image:</div>
        <input
          className="w-2/3 p-2 outline-none border mx-5"
          value={img}
          onChange={(ev) => setImg(ev.target.value)}
        />
      </div>
      <div className="mt-5 flex items-center w-full">
        <div className="w-1/3">Description:</div>
        <textarea
          className="w-2/3 p-2 outline-none border mx-5"
          value={desc}
          onChange={(ev) => setDesc(ev.target.value)}
        ></textarea>
      </div>
      <button
        onClick={() => updateActivity({ name, img, desc })}
        className="cursor-pointer my-5 border py-2 px-5 bg-green-200"
      >
        Save
      </button>
    </div>
  );
}
