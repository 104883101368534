import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC4pPPu0Tqk7nBh3O2SFlfmjQRMoYhqnjQ",

  authDomain: "student-portfolio-a6c09.firebaseapp.com",

  projectId: "student-portfolio-a6c09",

  storageBucket: "student-portfolio-a6c09.appspot.com",

  messagingSenderId: "436093550448",

  appId: "1:436093550448:web:1557268c1b7a447473d9b5",

  measurementId: "G-B441P1B33Z"
};

// Initialize Firebase

const fApp = initializeApp(firebaseConfig);
const fdb = getFirestore(fApp);
export { fdb };
