import { fdb } from "../../../fb";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

export default function Awards({ profile, setProfile, user }) {
  let [name, setName] = useState("");
  let [screenshot, setScreenshot] = useState("");
  let [confirm, setConfirm] = useState(-1);

  async function addAward(ev) {
    ev.preventDefault();
    let t = [...profile.awards, { name, screenshot }];
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { awards: t });
    setProfile({ ...profile, awards: t });
    setName("");
    setScreenshot("");
  }

  async function deleteAward(index) {
    let awards = profile.awards.filter((p, i) => i !== index);
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { awards });
    setProfile({ ...profile, awards });
    setConfirm(-1);
  }

  return (
    <div className="w-full">
      <form onSubmit={addAward} className="m-5 w-full">
        Name:
        <input
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          className="p-2 outline-none border mx-5"
        />
        Screenshot:
        <input
          value={screenshot}
          onChange={(ev) => setScreenshot(ev.target.value)}
          className="p-2 outline-none border mx-5"
        />
        <button className="border py-2 px-5 bg-green-200">Add</button>
      </form>
      <hr className="w-full" />
      <div className="w-full">
        {profile.awards.map((a, i) => (
          <div key={a.name} className="flex border-b">
            <div className="w-3/5 flex flex-col items-center border-r">
              <div className="text-xl pt-5">{a.name}</div>
              <img className="m-5 h-72" alt="screenshot" src={a.screenshot} />
            </div>
            <div className="w-2/5 p-5 flex justify-center items-center">
              <button
                onClick={() => (confirm === i ? deleteAward(i) : setConfirm(i))}
                className={
                  "cursor-pointer border py-2 px-5 " +
                  (confirm === i ? "bg-green-200" : "bg-red-200")
                }
              >
                {confirm === i ? "Confirm" : "Delete"}
              </button>
              {confirm === i && (
                <button
                  onClick={() => setConfirm(-1)}
                  className="cursor-pointer border py-2 px-5 bg-red-200"
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
