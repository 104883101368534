import { fdb } from "../../../fb";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

export default function Pictures({ profile, setProfile, user }) {
  let [img, setImg] = useState("");
  let [confirm, setConfirm] = useState(-1);

  async function addImage(ev) {
    ev.preventDefault();
    if (img === "") return;
    let pics = [...profile.pics, img];
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { pics });
    setProfile({ ...profile, pics });
  }

  async function deleteImage(index) {
    let pics = profile.pics.filter((p, i) => i !== index);
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { pics });
    setProfile({ ...profile, pics });
  }

  return (
    <div className="w-full">
      <form onSubmit={addImage} className="m-5">
        Add Image:
        <input
          value={img}
          onChange={(ev) => setImg(ev.target.value)}
          className="border p-2 outline-none mx-5 flex-grow"
        />
        <button className="cursor-pointer border py-2 px-5 bg-green-200">
          Add
        </button>
      </form>
      <hr className="w-full" />
      <div className="w-full flex flex-col">
        {profile.pics.map((p, i) => (
          <div key={p + i} className="flex flex-grow border-b">
            <div className="w-3/5 flex justify-center border-r">
              <img alt={p} className="h-72" src={p} />
            </div>
            <div className="w-2/5 flex justify-center items-center">
              <button
                onClick={() => (confirm === i ? deleteImage(i) : setConfirm(i))}
                className={
                  "cursor-pointer border py-2 px-5 " +
                  (confirm === i ? "bg-green-200" : "bg-red-200")
                }
              >
                {confirm === i ? "Confirm" : "Delete"}
              </button>
              {confirm === i && (
                <button
                  onClick={() => setConfirm(-1)}
                  className="cursor-pointer border py-2 px-5 bg-red-200"
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
