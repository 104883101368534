import { useState, useEffect, useCallback } from "react";
import { collection, getDocs } from "firebase/firestore";
import { fdb } from "../fb";
import { Link } from "react-router-dom";

function capitalize(s) {
  s = s.split(".");
  let name = "";
  for (let i = 0; i < s.length; i++)
    name += s[i][0].toUpperCase() + s[i].substring(1).toLowerCase() + " ";
  return name;
}

export default function Home() {
  let [users, setUsers] = useState([]);
  let [name, setName] = useState("");

  const loadUsers = useCallback(async () => {
    let q = await collection(fdb, "users");
    let ss = await getDocs(q);
    setUsers(ss.docs);
  }, [setUsers]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  if (name !== "") return <Menu name={name} setName={setName} />;

  return (
    <div className="flex flex-col items-center">
      <div className="my-5">
        {users.map((u, i) => (
          <div
            className="cursor-pointer"
            key={u.id}
            onClick={() => setName(u.id)}
          >
            {capitalize(u.id)}
          </div>
        ))}
        {name}
      </div>
      <hr className="w-full" />
      <Login setName={setName} />
    </div>
  );
}

function Menu({ name, setName }) {
  return (
    <div className="p-5 flex flex-col items-center">
      <div className="w-1/5 flex justify-around items-center">
        <Link className="border py-2 px-5" to={`/projects/${name}`}>
          Projects
        </Link>
        <Link className="border py-2 px-5" to={`/user/${name}`}>
          Profile
        </Link>
      </div>
      <a
        className="py-2 px-5 m-5 border"
        target="blank"
        href={`https://portfolio.btreecode.com/${name}`}
      >
        View Portfolio
      </a>
      <button className="border py-2 px-5" onClick={() => setName("")}>
        Go Back
      </button>
    </div>
  );
}

function Login({ setName }) {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  async function login(ev) {
    ev.preventDefault();
    setName(email.split("@")[0]);
  }

  return (
    <form onSubmit={login} className="w-4/5 my-5">
      <div className="w-full flex items-center space-x-5">
        <div className="w-1/2 text-right">E-mail: </div>
        <input
          onChange={(ev) => setEmail(ev.target.value)}
          value={email}
          type="email"
          className="w-1/2 border p-2 outline-none"
        />
      </div>
      <div className="w-full flex items-center space-x-5 outline-none">
        <div className="w-1/2 text-right">Password: </div>
        <input
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          type="password"
          className="w-1/2 border p-2 outline-none"
        />
      </div>
      <button className="px-5 py-2 border mt-2">Login</button>
    </form>
  );
}
