import { fdb } from "../../../fb";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

export default function Typer({ profile, setProfile, user }) {
  let [word, setWord] = useState("");
  let [words, setWords] = useState(new Array(profile.typer.length).fill(""));
  let [confirm, setConfirm] = useState(-1);

  async function updateTyper(ev) {
    for (let i = 0; i < words.length; i++) {
      if (words[i] === "") words[i] = profile.typer[i];
    }

    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { typer: words });
    setProfile({ ...profile, typer: words });
  }

  async function addTyper(ev) {
    ev.preventDefault();
    let typer = [...profile.typer, word];
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { typer });
    setProfile({ ...profile, typer });
    setWords(typer);
    setWord("");
  }

  async function deleteTyper(index) {
    let obj = { ...profile };
    obj.typer = profile.typer.filter((t, i) => i !== index);
    let docRef = await doc(fdb, "users", user);
    await updateDoc(docRef, { ...obj });
    setProfile(obj);
    setWords(obj.typer);
  }

  return (
    <div className="w-full flex flex-col items-center">
      <form onSubmit={addTyper} className="m-5">
        <input
          value={word}
          onChange={(ev) => setWord(ev.target.value)}
          placeholder="add new word..."
          className="p-2 outline-none border mx-5"
        />
        <button className="border py-2 px-5 bg-green-200">Add</button>
      </form>
      <hr className="w-full" />

      {words.map((w, i) => (
        <div className="mt-5 flex w-4/5" key={i}>
          <div className="w-1/2 flex justify-end">
            <input
              onChange={(ev) => {
                if (ev.target.value === "") return;
                let t = [...words];
                t[i] = ev.target.value;
                setWords(t);
              }}
              placeholder={profile.typer[i]}
              className="p-2 outline-none border mx-5"
            />
          </div>
          <div className="w-1/2 flex justify-start">
            <button
              onClick={updateTyper}
              className="border py-2 px-5 bg-yellow-200"
            >
              Update
            </button>
            <button
              onClick={() => (confirm === i ? deleteTyper(i) : setConfirm(i))}
              className={
                "cursor-pointer border py-2 px-5 " +
                (confirm === i ? "bg-green-200" : "bg-red-200")
              }
            >
              {confirm === i ? "Confirm" : "Delete"}
            </button>
            {confirm === i && (
              <button
                onClick={() => setConfirm(-1)}
                className="cursor-pointer border py-2 px-5 bg-red-200"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
