import { useState, useEffect, useCallback } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fdb } from "../../fb";
import Loading from "../../components/Loading";
import { useParams } from "react-router-dom";

import BasicInfo from "./fields/BasicInfo";
import Pictures from "./fields/Pictures";
import Typer from "./fields/Typer";
import Awards from "./fields/Awards";
import Colors from "./fields/Colors";
import Activities from "./fields/Activities";

export default function User() {
  let { user } = useParams();
  let [profile, setProfile] = useState(undefined);
  let [loading, setLoading] = useState(true);
  let [view, setView] = useState(0);

  let tabs = [
    "Basic Information",
    "Pictures",
    "Typer",
    "Awards",
    "Colors",
    "Activities"
  ];
  let views = [
    <BasicInfo
      key="basicinfo"
      profile={profile}
      setProfile={setProfile}
      user={user}
    />,
    <Pictures
      key="pictures"
      profile={profile}
      setProfile={setProfile}
      user={user}
    />,
    <Typer key="typer" profile={profile} setProfile={setProfile} user={user} />,
    <Awards
      key="awards"
      profile={profile}
      setProfile={setProfile}
      user={user}
    />,
    <Colors
      key="colors"
      profile={profile}
      setProfile={setProfile}
      user={user}
    />,
    <Activities
      key="activities"
      profile={profile}
      setProfile={setProfile}
      user={user}
    />
  ];

  const loadUser = useCallback(async () => {
    if (user === "") return;

    let docRef = await doc(fdb, "users", user);
    let ss = await getDoc(docRef);

    let obj = { ...ss.data() };
    if (!obj.aboutme) obj.aboutme = "";
    if (!obj.activities) obj.activities = [];
    if (!obj.awards) obj.awards = [];
    if (!obj.colors)
      obj.colors = { background: "", dark: "", primary: "", secondary: "" };
    if (!obj.email) obj.email = "";
    if (!obj.name) obj.name = "";
    if (!obj.pics) obj.pics = [];
    if (!obj.typer) obj.typer = [];
    if (!obj.video) obj.video = "";

    await setDoc(docRef, obj);
    setProfile(obj);
    setLoading(false);
  }, [user]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  if (loading) return <Loading />;

  return (
    <div className="flex flex-col items-center">
      <div className="flex justify-center items-center space-x-5 my-5">
        {tabs.map((t, i) => (
          <div
            className={"cursor-pointer " + (i === view ? "font-bold" : "")}
            onClick={() => setView(i)}
            key={t}
          >
            {t}
          </div>
        ))}
      </div>
      <hr className="w-full" />
      {views[view]}
    </div>
  );
}

// function BasicInfo({ profile, setProfile, user }) {
//   let [name, setName] = useState("");
//   let [email, setEmail] = useState("");
//   let [aboutme, setAboutme] = useState("");
//   let [video, setVideo] = useState("");

//   async function updateInfo(ev) {
//     ev.preventDefault();
//     let obj = {};
//     if (name !== "") obj.name = name;
//     if (email !== "") obj.email = email;
//     if (aboutme !== "") obj.aboutme = aboutme;
//     if (video !== "") obj.video = LOOM1 + video + LOOM2;
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { ...obj });
//     setProfile({ ...profile, ...obj });
//   }

//   return (
//     <form onSubmit={updateInfo} className="w-full flex flex-col items-center">
//       <div className="m-5">
//         Name:
//         <input
//           value={name}
//           onChange={(ev) => setName(ev.target.value)}
//           placeholder={profile.name}
//           className="border p-2 outline-none mx-5"
//         />
//         E-Mail:
//         <input
//           value={email}
//           onChange={(ev) => setEmail(ev.target.value)}
//           placeholder={profile.email}
//           className="border p-2 outline-none mx-5"
//         />
//       </div>
//       <hr className="w-full" />
//       <div className="w-full m-5 flex flex-col items-center">
//         About Me
//         <textarea
//           value={aboutme}
//           onChange={(ev) => setAboutme(ev.target.value)}
//           placeholder={profile.aboutme}
//           className="w-3/5 m-5 border p-2 outline-none"
//         ></textarea>
//       </div>
//       <hr className="w-full" />
//       <div className="m-5 w-4/5 flex items-center">
//         Video Embed URL:
//         <input
//           value={video}
//           onChange={(ev) => setVideo(ev.target.value)}
//           className="border p-2 outline-none mx-5 flex-grow"
//           placeholder={profile.video?.substring(
//             profile.video.indexOf("src=") + 5,
//             profile.video.indexOf("frameborder") - 2
//           )}
//         />
//       </div>
//       <hr className="w-full" />
//       <button className="m-5 border py-2 px-5 bg-green-200">Update</button>
//     </form>
//   );
// }
// function Pictures({ profile, setProfile, user }) {
//   let [img, setImg] = useState("");

//   async function addImage(ev) {
//     ev.preventDefault();
//     if (img === "") return;
//     let pics = [...profile.pics, img];
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { pics });
//     setProfile({ ...profile, pics });
//   }

//   async function deleteImage(index) {
//     let pics = profile.pics.filter((p, i) => i !== index);
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { pics });
//     setProfile({ ...profile, pics });
//   }

//   return (
//     <div className="w-full">
//       <form onSubmit={addImage} className="m-5">
//         Add Image:
//         <input
//           value={img}
//           onChange={(ev) => setImg(ev.target.value)}
//           className="border p-2 outline-none mx-5 flex-grow"
//         />
//         <button className="cursor-pointer border py-2 px-5 bg-green-200">
//           Add
//         </button>
//       </form>
//       <hr className="w-full" />
//       <div className="w-full flex flex-col">
//         {profile.pics.map((p, i) => (
//           <div key={p + i} className="flex flex-grow border-b">
//             <div className="w-3/5 flex justify-center border-r">
//               <img alt={p} className="h-72" src={p} />
//             </div>
//             <div className="w-2/5 flex justify-center items-center">
//               <div
//                 onClick={() => deleteImage(i)}
//                 className="cursor-pointer border py-2 px-5 text-2xl bg-red-200"
//               >
//                 Delete
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }
// function Typer({ profile, setProfile, user }) {
//   let [word, setWord] = useState("");
//   let [words, setWords] = useState(new Array(profile.typer.length).fill(""));

//   async function updateTyper(ev) {
//     for (let i = 0; i < words.length; i++) {
//       if (words[i] === "") words[i] = profile.typer[i];
//     }

//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { typer: words });
//     setProfile({ ...profile, typer: words });
//   }

//   async function addTyper(ev) {
//     ev.preventDefault();
//     let typer = [...profile.typer, word];
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { typer });
//     setProfile({ ...profile, typer });
//     setWords(typer);
//     setWord("");
//   }

//   async function deleteTyper(index) {
//     let obj = { ...profile };
//     obj.typer = profile.typer.filter((t, i) => i !== index);
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { ...obj });
//     setProfile(obj);
//     setWords(obj.typer);
//   }

//   return (
//     <div className="w-full">
//       <form onSubmit={addTyper} className="m-5">
//         <input
//           value={word}
//           onChange={(ev) => setWord(ev.target.value)}
//           placeholder="add new word..."
//           className="p-2 outline-none border mx-5"
//         />
//         <button className="border py-2 px-5 bg-green-200">Add</button>
//       </form>
//       <hr className="w-full" />

//       {words.map((w, i) => (
//         <div className="m-5" key={i}>
//           <input
//             onChange={(ev) => {
//               if (ev.target.value === "") return;
//               let t = [...words];
//               t[i] = ev.target.value;
//               setWords(t);
//             }}
//             placeholder={profile.typer[i]}
//             className="p-2 outline-none border mx-5"
//           />
//           <button
//             onClick={updateTyper}
//             className="border py-2 px-5 bg-green-200"
//           >
//             Update
//           </button>
//           <button
//             onClick={() => deleteTyper(i)}
//             className="border py-2 px-5 bg-red-200"
//           >
//             Delete
//           </button>
//         </div>
//       ))}
//     </div>
//   );
// }
// function Awards({ profile, setProfile, user }) {
//   let [name, setName] = useState("");
//   let [screenshot, setScreenshot] = useState("");

//   async function addAward(ev) {
//     ev.preventDefault();
//     let t = [...profile.awards, { name, screenshot }];
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { awards: t });
//     setProfile({ ...profile, awards: t });
//     setName("");
//     setScreenshot("");
//   }

//   async function deleteAward(index) {
//     let awards = profile.awards.filter((p, i) => i !== index);
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { awards });
//     setProfile({ ...profile, awards });
//   }

//   return (
//     <div className="w-full">
//       <form onSubmit={addAward} className="m-5 w-full">
//         Name:
//         <input
//           value={name}
//           onChange={(ev) => setName(ev.target.value)}
//           className="p-2 outline-none border mx-5"
//         />
//         Screenshot:
//         <input
//           value={screenshot}
//           onChange={(ev) => setScreenshot(ev.target.value)}
//           className="p-2 outline-none border mx-5"
//         />
//         <button className="border py-2 px-5 bg-green-200">Add</button>
//       </form>
//       <hr className="w-full" />
//       <div className="w-full">
//         {profile.awards.map((a, i) => (
//           <div key={a.name} className="flex border-b">
//             <div className="w-3/5 flex flex-col items-center border-r">
//               <div className="text-xl pt-5">{a.name}</div>
//               <img className="m-5 h-72" alt="screenshot" src={a.screenshot} />
//             </div>
//             <div className="w-2/5 p-5 flex justify-center items-center">
//               <div
//                 onClick={() => deleteAward(i)}
//                 className="cursor-pointer border py-2 px-5 text-2xl bg-red-200"
//               >
//                 Delete
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }
// function Colors({ profile, setProfile, user }) {
//   let [colors, setColors] = useState({ ...profile.colors });

//   async function updateColors() {
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { colors });
//     setProfile({ ...profile, colors });
//   }

//   return (
//     <div className="w-full flex flex-col items-center">
//       {Object.keys(colors)
//         .sort()
//         .map((c, i) => (
//           <div className="mt-5 flex w-4/5 items-center" key={c}>
//             <div className="w-1/3 text-left">
//               {c[0].toUpperCase() + c.substring(1)}:{" "}
//             </div>
//             <input
//               className="p-2 border outline-none w-1/3"
//               value={colors[c]}
//               onChange={(ev) => setColors({ ...colors, [c]: ev.target.value })}
//             />
//             <div className="w-1/3">
//               <div
//                 className="w-full p-5"
//                 style={{ backgroundColor: colors[c] }}
//               ></div>
//             </div>
//           </div>
//         ))}
//       <div
//         onClick={updateColors}
//         className="cursor-pointer mt-5 border py-2 px-5 bg-green-200"
//       >
//         Update
//       </div>
//     </div>
//   );
// }
// function Activities({ profile, setProfile, user }) {
//   let [activities, setActivities] = useState([...profile.activities]);
//   let [desc, setDesc] = useState("");
//   let [img, setImg] = useState("");
//   let [name, setName] = useState("");
//   let [edit, setEdit] = useState(-1);

//   async function addActivity(ev) {
//     ev.preventDefault();
//     let t = [...profile.activities, { name, desc, img }];
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { activities: t });
//     setProfile({ ...profile, activities: t });
//     setActivities(t);
//     setName("");
//     setImg("");
//     setDesc("");
//   }

//   async function updateActivity(activity) {
//     let t = activities.map((a, i) => (i === edit ? activity : a));
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { activities: t });
//     setProfile({ ...profile, activities: t });
//     setActivities([...t]);
//     setEdit(-1);
//   }

//   async function deleteActivity(index) {
//     let activities = profile.activities.filter((p, i) => i !== index);
//     let docRef = await doc(fdb, "users", user);
//     await updateDoc(docRef, { activities });
//     setProfile({ ...profile, activities });
//     setActivities([...activities]);
//   }

//   return (
//     <div className="w-full flex flex-col items-center">
//       <form onSubmit={addActivity} className="w-4/5">
//         <div className="mt-5 flex items-center w-full">
//           <div className="w-1/3">Name:</div>
//           <input
//             className="w-2/3 p-2 outline-none border mx-5"
//             value={name}
//             onChange={(ev) => setName(ev.target.value)}
//           />
//         </div>
//         <div className="mt-5 flex items-center w-full">
//           <div className="w-1/3">Image:</div>
//           <input
//             className="w-2/3 p-2 outline-none border mx-5"
//             value={img}
//             onChange={(ev) => setImg(ev.target.value)}
//           />
//         </div>
//         <div className="mt-5 flex items-center w-full">
//           <div className="w-1/3">Description:</div>
//           <textarea
//             className="w-2/3 p-2 outline-none border mx-5"
//             value={desc}
//             onChange={(ev) => setDesc(ev.target.value)}
//           ></textarea>
//         </div>
//         <button className="cursor-pointer my-5 border py-2 px-5 bg-green-200">
//           Add
//         </button>
//       </form>
//       <hr className="w-full" />
//       <div className="">
//         {activities.map((a, i) =>
//           edit !== i ? (
//             <div className="w-full flex border-b" key={a.name}>
//               <div className="w-2/3 border-r p-5">
//                 <div className="font-bold">{a.name}</div>
//                 <img className="mt-5" alt="activity" src={a.img} />
//               </div>
//               <div className="w-1/3">
//                 <div className="text-sm p-5">{a.desc}</div>
//                 <div>
//                   <button
//                     onClick={() => setEdit(i)}
//                     className="cursor-pointer my-5 border py-2 px-5 bg-green-200"
//                   >
//                     Edit
//                   </button>
//                   <button
//                     onClick={() => deleteActivity(i)}
//                     className="cursor-pointer my-5 border py-2 px-5 bg-red-200"
//                   >
//                     Delete
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <EditActivity
//               key={a.name}
//               activity={a}
//               updateActivity={updateActivity}
//             />
//           )
//         )}
//       </div>
//     </div>
//   );
// }

// function EditActivity({ activity, updateActivity }) {
//   let [name, setName] = useState(activity.name);
//   let [img, setImg] = useState(activity.img);
//   let [desc, setDesc] = useState(activity.desc);

//   return (
//     <div>
//       <div className="mt-5 flex items-center w-full">
//         <div className="w-1/3">Name:</div>
//         <input
//           className="w-2/3 p-2 outline-none border mx-5"
//           value={name}
//           onChange={(ev) => setName(ev.target.value)}
//         />
//       </div>
//       <div className="mt-5 flex items-center w-full">
//         <div className="w-1/3">Image:</div>
//         <input
//           className="w-2/3 p-2 outline-none border mx-5"
//           value={img}
//           onChange={(ev) => setImg(ev.target.value)}
//         />
//       </div>
//       <div className="mt-5 flex items-center w-full">
//         <div className="w-1/3">Description:</div>
//         <textarea
//           className="w-2/3 p-2 outline-none border mx-5"
//           value={desc}
//           onChange={(ev) => setDesc(ev.target.value)}
//         ></textarea>
//       </div>
//       <button
//         onClick={() => updateActivity({ name, img, desc })}
//         className="cursor-pointer my-5 border py-2 px-5 bg-green-200"
//       >
//         Save
//       </button>
//     </div>
//   );
// }
