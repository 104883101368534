import { Routes, Route, Link } from "react-router-dom";
import Projects from "./pages/Projects/Projects";
import User from "./pages/User/User";
import Home from "./pages/Home";

import "./styles.css";

export default function App() {
  return (
    <div className="App">
      <Header />
      <hr />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects/:user" element={<Projects />} />
        <Route path="/user/:user" element={<User />} />
      </Routes>
    </div>
  );
}

function Header() {
  return (
    <div className="flex">
      <div className="flex-grow py-5 text-2xl font-bold tracking-widest">
        <Link to="/">Portfolio Admin Tool</Link>
      </div>
    </div>
  );
}
